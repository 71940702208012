import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Base from '../../../src/js/classes/Base'

export default class ServiceExpander extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {
				button: '.c-ServiceExpander__button',
				body: '.c-ServiceExpander__body',
			},
			settings: {},
			options: {},
			state: {
				active: false,
			},
		})
	}

	init() {
		this.addEvents()
	}

	onStateChange(newState) {
		//
		if ('active' in newState) {
			if (newState.active) {
				this.active()
			} else {
				this.inactive()
			}
		}
	}

	stateTriggerMenu() {
		this.setState({
			active: !this.state.active,
		})
	}

	active() {
		this.element.classList.add('active')

		this.closeSibingAccordions()

		GSAP.to(this.elements.body, {
			height: 'auto',
			duration: 0.5,
			ease: 'ease.inOut',
			onComplete: () => {
				this.updateScrollTrigger()
			},
		})
	}

	inactive() {
		this.element.classList.remove('active')

		GSAP.to(this.elements.body, {
			height: 0,
			duration: 0.5,
			ease: 'ease.inOut',
			onComplete: () => {
				this.updateScrollTrigger()
			},
		})
	}

	getSiblings(element) {
		let parent = this.element.parentNode
		let children = [...parent.children]

		return children.filter((child) => child !== element)
	}

	closeSibingAccordions() {
		this.siblings = this.getSiblings(this.element)

		this.siblings.forEach((sibling) => {
			sibling.getScripts && sibling.getScripts.state.active && sibling.getScripts.setState({ active: false })
		})
	}

	addEvents() {
		this.elements.button.addEventListener('click', this.stateTriggerMenu.bind(this))
	}

	updateScrollTrigger() {
		ScrollTrigger.refresh()
	}

	unmount() {}
}
