const Utils = {
	isTouchDevice() {
		const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

		if (isTouch) {
			document.body.classList.add('touchscreen')
		} else {
			document.body.classList.add('desktop')
		}

		return isTouch
	},

	getSiteSettingsAsAttributes() {
		const body = document.body
		const attributes = {}

		if (body) {
			for (const attr of body.attributes) {
				attributes[attr.name] = attr.value
			}
		}

		return attributes
	},

	setElementViewportHeight() {
		const vh = window.innerHeight * 0.01

		document.documentElement.style.setProperty('--vh', `${vh}px`)
	},

	parseAttribute(value) {
		try {
			return JSON.parse(value)
		} catch (e) {
			return value
		}
	},

	toCamelCase(string) {
		return string.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())
	},
}

export { Utils }
