import Base from '../../../src/js/classes/Base'
import VideoHelper from '../../../src/js/helpers/VideoHelper'

export default class Video extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {
				desktopVideo: '.c-Video--desktop',
				desktopVideoSource: '.c-Video--desktop source',
				desktopVideoWrapper: '.c-Video__video__wrapper--desktop',
				mobileVideo: '.c-Video--mobile',
				mobileVideoSource: '.c-Video--mobile source',
				mobileVideoWrapper: '.c-Video__video__wrapper--mobile',
				playBtnDesktop: '.c-Video__button--desktop',
				playBtnMobile: '.c-Video__button--mobile',
			},
			settings: {
				videoPlatform: '',
				showMobileVideo: '',
				addVideoControls: '',
			},
		})
	}

	init() {
		this.initVideoHelpers()
	}

	initVideoHelpers() {
		// TO DOUBLE CHECK

		if (this.elements.desktopVideo) {
			// TEMP MEDIA SIZE
			const desktopMediaQuery = '(min-width: 1025px)'

			this.desktopVideoHelper = new VideoHelper(
				this.elements.desktopVideo,
				this.elements.desktopVideoSource,
				this.settings.videoPlatform,
				desktopMediaQuery,
				this.settings.showMobileVideo,
				this.settings.addVideoControls,
				this.elements.desktopVideoWrapper,
				this.settings.addVideoControls ? this.elements.playBtnDesktop : null
			)
		}

		if (this.elements.mobileVideo) {
			// TEMP MEDIA SIZE
			const mobileMediaQuery = '(max-width: 1024px)'

			this.mobileVideoHelper = new VideoHelper(
				this.elements.mobileVideo,
				this.elements.mobileVideoSource,
				this.settings.videoPlatform,
				mobileMediaQuery,
				this.settings.showMobileVideo,
				this.settings.addVideoControls,
				this.elements.mobileVideoWrapper,
				this.settings.addVideoControls && this.settings.showMobileVideo ? this.elements.playBtnMobile : null
			)
		}
	}

	addEvents() {}

	removeEvents() {}

	resize() {}

	update() {}

	unmount() {
		this.desktopVideoHelper.disconnect()

		if (this.elements.mobileVideo) {
			this.mobileVideoHelper.disconnect()
		}
	}
}
