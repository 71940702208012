import Base from '../../../src/js/classes/Base'

export default class Burger extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {},
			state: {
				toggled: false,
			},
			classes: {
				isActive: 'is-active',
			},
			settings: {},
			options: {},
		})
	}

	init() {
		this.addEvents()
	}

	onStateChange(newState) {
		if ('toggled' in newState) {
			if (newState.toggled) {
				this.active()
			} else {
				this.inactive()
			}
		}
	}

	active() {
		this.element.classList.add(this.classes.isActive)
	}

	inactive() {
		this.element.classList.remove(this.classes.isActive)
	}

	addEvents() {
		//
		this.element.addEventListener('click', () => {
			this.setState({ toggled: !this.state.toggled })

			window.emitter.emit('menuToggled')
		})

		window.emitter.on('menuClosed', () => {
			this.setState({ toggled: false })
		})
	}

	removeEvents() {}

	unmount() {}
}
