import gsap from 'gsap'
import Base from '../../../src/js/classes/Base'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class Accordion extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {
				title: '.c-Accordion__title',
				body: '.c-Accordion__body',
			},
			settings: {},
			state: {
				isOpen: false,
			},
			options: {},
			classes: {
				open: 'open',
			},
		})
		// We need the settings to set the state
		this.state.isOpen = this.settings.defaultOpen && this.settings.defaultOpen == 1 ? true : false
	}

	init() {
		this.addEvents()
	}

	onStateChange(newState) {
		if ('isOpen' in newState) {
			if (newState.isOpen) {
				const event = new CustomEvent('accordion-open')
				this.element.dispatchEvent(event)
				this.open()
			} else {
				const event = new CustomEvent('accordion-close')
				this.element.dispatchEvent(event)
				this.close()
			}
		}
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen,
		})
	}

	open() {
		this.element.classList.add(this.classes.open)
		this.elements.title.setAttribute('aria-expanded', 'true')
		this.closeSibingAccordions()

		gsap.to(this.elements.body, {
			height: 'auto',
			duration: 0.5,
			ease: 'inOut',
			onComplete: () => {
				this.updateScrollTrigger()
			},
		})
	}

	close() {
		this.element.classList.remove(this.classes.open)
		this.elements.title.setAttribute('aria-expanded', 'false')
		gsap.to(this.elements.body, {
			height: 0,
			duration: 0.5,
			ease: 'inOut',
			onComplete: () => {
				this.updateScrollTrigger()
			},
		})
	}

	closeSibingAccordions() {
		this.siblings = this.getSiblings(this.element)
		this.siblings.forEach((sibling) => {
			sibling.getScripts && sibling.getScripts.setState({ isOpen: false })
		})
	}

	getSiblings(element) {
		let parent = this.element.parentNode
		let children = [...parent.children]
		return children.filter((child) => child !== element)
	}

	updateScrollTrigger() {
		ScrollTrigger.refresh()
	}

	addEvents() {
		this.elements.title.addEventListener('click', this.toggle.bind(this))
	}

	removeEvents() {
		this.elements.title.removeEventListener('click', this.toggle.bind(this))
	}

	unmount() {
		this.removeEvents()
	}
}
