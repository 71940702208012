import ScrollTrigger from 'gsap/ScrollTrigger'
import Base from '../../../src/js/classes/Base'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default class FAQ extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {
				groups: '.c-Accordions',
				navItems: '.s-FAQ ul li',
			},
			classes: {
				active: 'active',
			},
		})
	}

	init() {
		this.elements.groups = gsap.utils.toArray(this.elements.groups)
		this.elements.navItems = gsap.utils.toArray(this.elements.navItems)

		if (this.settings.showAffixNav && this.settings.showAffixNav === 1) {
			this.elements.groups.forEach((group, index) => {
				ScrollTrigger.create({
					trigger: group,
					start: 'top top',
					end: 'bottom top',
					onEnter: () => this.setActiveNavItem(index),
					onEnterBack: () => this.setActiveNavItem(index),
				})
			})
		}
		this.addEvents()
	}

	addEvents() {
		this.elements.navItems.forEach((item) => {
			item.addEventListener('click', () => {
				const index = item.getAttribute('data-index')
				gsap.to(window, {
					duration: 0.5,
					ease: 'inOut',
					scrollTo: {
						y: this.elements.groups[index],
						offsetY: -1, // Force enter
					},
				})
			})
		})
	}

	removeEvents() {
		// Remove event listeners
	}

	setActiveNavItem(index) {
		console.log('triggering!')
		this.elements.navItems.forEach((item) => item.classList.remove(this.classes.active))
		if (this.elements.navItems[index]) {
			this.elements.navItems[index].classList.add(this.classes.active)
		}
	}

	resize() {
		// Handle resize
	}

	update() {
		// Update component
	}

	unmount() {}
}
