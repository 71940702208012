import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Base from '../../../src/js/classes/Base'

export default class Marquee extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {
				clone: '.c-Marquee__items',
			},
			settings: {
				speed: '',
			},
		})

		this.clones = []
		this.isInViewport = false
		// console.log(this.globals)
	}

	init() {
		this.createScrollTrigger()
	}

	createClones() {
		if (this.clones.length > 0) {
			this.tl && this.tl.progress(0).kill()

			this.clones.forEach((clone, idx) => {
				if (idx !== 0) {
					this.element.removeChild(clone)
				}
			})
		}

		this.clones = []
		this.contentWidth = this.elements.clone.offsetWidth
		this.desiredWidth = window.innerWidth
		this.currentWidth = this.contentWidth

		this.clones.push(this.elements.clone)

		while (this.currentWidth < this.desiredWidth + this.contentWidth) {
			this.clones.push(this.elements.clone.cloneNode(true))
			this.currentWidth += this.contentWidth
		}

		this.clones.forEach((clone) => {
			this.element.appendChild(clone)
		})

		this.animate()
	}

	createScrollTrigger() {
		/**
		 * PLAY/PAUSE .TL IF IN THE VIEWPORT
		 */

		ScrollTrigger.create({
			trigger: this.element,
			start: 'top bottom',
			end: 'bottom top',
			onEnter: () => {
				this.isInViewport = true
				this.tl && this.tl.play()
			},
			onEnterBack: () => {
				this.isInViewport = true
				this.tl && this.tl.play()
			},
			onLeave: () => {
				this.isInViewport = false
				this.tl && this.tl.pause()
			},
			onLeaveBack: () => {
				this.isInViewport = false
				this.tl && this.tl.pause()
			},
		})
	}

	play() {
		this.tl.play()
	}

	pause() {
		this.tl.pause()
	}

	animate() {
		this.tl = GSAP.timeline({ repeat: -1, paused: true })

		this.tl.to(this.clones, {
			duration: this.settings.speed,
			ease: 'none',
			xPercent: () => -100,
		})

		if (this.isInViewport) {
			this.tl.play()
		}
	}

	resize() {
		this.createClones()
	}

	update() {}

	unmount() {
		if (this.tl) {
			this.tl.kill()
			this.tl = null
		}
	}
}
