import Flickity from 'flickity'
import Base from '../../../src/js/classes/Base'

export default class Slider extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {
				slider: '.c-Slider__slider',
			},
			settings: {
				sliderType: '',
			},
		})
	}

	init() {
		this.buildSlider()
	}

	buildSlider() {
		//

		this.flkty = new Flickity(this.elements.slider, {
			wrapAround: false,
			pageDots: false,
			initialIndex: 0,
			prevNextButtons: false,
			resize: true,
			draggable: true,
			autoPlay: false,
			adaptiveHeight: false,
			percentPosition: true,
			pauseAutoPlayOnHover: false,
			cellAlign: 'left',
			contain: true,
			selectedAttraction: 0.08,
			friction: 0.95,
		})
	}

	resize() {}

	update() {}

	unmount() {}
}
