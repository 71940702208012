export default class ScriptComponentLoader {
	constructor() {}

	organiseScriptOnLoad(array, componentMap, attr) {
		const componentElements = document.querySelectorAll(`[${attr}]`)

		// Convert NodeList to an array
		const elementsArray = [...componentElements]
		// Sort the elements based on their position in the DOM tree
		elementsArray.sort((a, b) => {
			const aPosition = Array.from(a.parentElement.children).indexOf(a)
			const bPosition = Array.from(b.parentElement.children).indexOf(b)
			return aPosition - bPosition
		})

		elementsArray.forEach((el) => {
			const componentName = el.getAttribute(`${attr}`)

			if (componentMap[componentName]) {
				array.push(new componentMap[componentName](el))
				array[array.length - 1].init()
				console.log(`Initialized component ${componentName}`)
			} else {
				console.warn(`Component ${componentName} not found in componentMap.`)
			}
		})
	}

	getAndLoad(array, componentMap) {
		this.organiseScriptOnLoad(array, componentMap, 'data-component')
		this.organiseScriptOnLoad(array, componentMap, 'data-section')
		this.organiseScriptOnLoad(array, componentMap, 'data-element')
	}

	organiseScriptOnChange(array, componentMap, attr, data) {
		// Get the components present in the new page
		const newComponentElements = Array.from(data.next.container.querySelectorAll(`[${attr}]`))
		// Sort the newComponentElements based on their position in the DOM tree
		newComponentElements.sort((a, b) => {
			const aPosition = Array.from(a.parentElement.children).indexOf(a)
			const bPosition = Array.from(b.parentElement.children).indexOf(b)
			return aPosition - bPosition
		})

		// Initialize components in the sorted order
		newComponentElements.forEach((el) => {
			const componentName = el.getAttribute(`${attr}`)
			if (componentMap[componentName]) {
				array.push(new componentMap[componentName](el))
				array[array.length - 1].init()
				console.log(`Initialized component ${componentName}`)
			} else {
				console.warn(`Component ${componentName} not found in componentMap.`)
			}
		})
	}

	filterAndLoad(array, componentMap, data) {
		this.organiseScriptOnChange(array, componentMap, 'data-component', data)
		this.organiseScriptOnChange(array, componentMap, 'data-section', data)
	}

	unmountComponents(array, data) {
		array.forEach((component) => {
			if (data.current.container.contains(component.element)) {
				component.unmount()
				console.log(`unmounting`)
			}
		})
	}
}
