import { Utils } from '../utils/utils'

import Bonobo from '../main'

export default class Base {
	constructor({ element, elements, options, classes, state }) {
		this.globals = new Bonobo().globals

		this.element = element
		this.element.getScripts = this
		this.elements = {}
		this.options = options || {}
		this.settings = {}
		this.classes = classes || {}
		this.state = state || {}

		this.element.bonobo = this

		this.createElementsObject(elements)
		this.getDataOptions()
		this.getSettings()
	}

	getDataOptions() {
		const dataOptions = this.element.getAttribute('data-options')

		if (dataOptions) {
			const parsedData = JSON.parse(dataOptions)

			this.options = parsedData
		}
	}

	getSettings() {
		for (const attribute of this.element.attributes) {
			if (attribute.name.startsWith('data-s-')) {
				const key = Utils.toCamelCase(attribute.name.replace('data-s-', ''))

				this.settings[key] = Utils.parseAttribute(attribute.value)
			}
		}
	}

	createElementsObject(elements) {
		for (const key in elements) {
			if (elements.hasOwnProperty(key)) {
				const selector = elements[key]
				/**
				 * The purpose of this first check is to handle cases where the elements object might
				 * contain actual DOM elements or collections of DOM elements (NodeLists) directly,
				 * rather than just CSS selectors.
				 */
				if (selector instanceof window.HTMLElement || selector instanceof window.NodeList) {
					this.elements[key] = selector
				} else {
					this.elements[key] = [...this.element.querySelectorAll(selector)]

					if (this.elements[key].length === 0) {
						this.elements[key] = null
					} else if (this.elements[key].length === 1) {
						this.elements[key] = this.element.querySelector(selector)
					}
				}
			}
		}
	}

	toKebabCase(str) {
		return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
	}

	setState(changes) {
		let stateChanges = {}

		Object.keys(changes).forEach((key) => {
			// Check if the key is in the state object
			if (this.state.hasOwnProperty(key)) {
				// Only update state if there's a change in value
				if (this.state[key] !== changes[key]) {
					stateChanges[key] = changes[key]
					this.state[key] = stateChanges[key]
				}
			}
		})

		// Trigger callback with the changed state
		this.onStateChange(stateChanges)
	}

	onStateChange(stateChanges) {
		console.log('State changes:', stateChanges)
		// Placeholder method to be overridden by subclasses
	}
}
