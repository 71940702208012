import GSAP from 'gsap'
import Base from '../../../src/js/classes/Base'

export default class MenuAccordion extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {
				btn: '.c-MenuAccordion__button',
				body: '.c-MenuAccordion__body',
			},
			settings: {},
			options: {},
			classes: {
				open: 'is-open',
			},
			state: {
				isOpen: false,
			},
		})
	}

	init() {
		this.addEvents()
	}

	onStateChange(newState) {
		if ('isOpen' in newState) {
			if (newState.isOpen) {
				this.open()
			} else {
				this.close()
			}
		}
	}

	stateTriggerAccordion() {
		this.setState({
			isOpen: !this.state.isOpen,
		})
	}

	open() {
		this.element.classList.add(this.classes.open)

		GSAP.to(this.elements.body, {
			height: 'auto',
			duration: 0.5,
		})
	}

	close() {
		this.element.classList.remove(this.classes.open)

		GSAP.to(this.elements.body, {
			height: 0,
			duration: 0.5,
		})
	}

	addEvents() {
		this.elements.btn.addEventListener('click', this.stateTriggerAccordion.bind(this))
	}

	unmount() {}
}
