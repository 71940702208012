import ScriptComponentLoader from './ScriptComponentLoader'
import PageTransition from '@views/elements/e-PageTransition/e-PageTransition'
import BarbaBodyHelper from './BarbaBodyHelper'

/**
 * SCRIPTS
 */

/** SECTIONS */
import Gridder from '@views/sections/s-Gridder/s-Gridder'
import FAQ from '@views/sections/s-FAQ/s-FAQ'
import Header from '@views/sections/s-Header/s-Header'
import Process from '@views/sections/s-Process/s-Process'
import Pullouts from '@views/sections/s-Pullouts/s-Pullouts'

/** COMPONENTS */
import Marquee from '@views/components/c-Marquee/c-Marquee'
import Video from '@views/components/c-Video/c-Video'
import Accordion from '@views/components/c-Accordion/c-Accordion'
import Menu from '@views/components/c-Menu/c-Menu'
import Slider from '@views/components/c-Slider/c-Slider'
import MenuAccordion from '@views/components/c-MenuAccordion/c-MenuAccordion'
import ServiceExpander from '@views/components/c-ServiceExpander/c-ServiceExpander'

/** ELEMENTS */
import Burger from '@views/elements/e-Burger/e-Burger'

const scriptsMap = {
	marquee: Marquee,
	gridder: Gridder,
	process: Process,
	video: Video,
	accordion: Accordion,
	faq: FAQ,
	menu: Menu,
	header: Header,
	slider: Slider,
	menuAccordion: MenuAccordion,
	burger: Burger,
	serviceExpander: ServiceExpander,
	pullouts: Pullouts,
}

export default class Page {
	constructor(config = {}) {
		this.scriptRefs = []
		this.hasTransitions = config.hasTransitions

		this.scriptComponentLoader = new ScriptComponentLoader()
		this.bodyHelper = new BarbaBodyHelper()

		if (this.hasTransitions) {
			this.pageTransition = new PageTransition({
				element: document.querySelector('.e-PageTransition'),
			})
		}
	}

	init() {
		this.scriptRefs = []

		this.scriptComponentLoader.getAndLoad(this.scriptRefs, scriptsMap)
	}

	mountScripts(data) {
		return new Promise((resolve) => {
			this.scriptRefs = []

			this.scriptComponentLoader.filterAndLoad(this.scriptRefs, scriptsMap, data)

			resolve()
		})
	}

	unmountScripts(data) {
		this.bodyHelper.getClassesAndDataAttributes(data)

		return new Promise((resolve) => {
			this.scriptComponentLoader.unmountComponents(this.scriptRefs, data)

			resolve()
		})
	}

	genericPageOnce() {
		this.pageTransition.once()
	}

	genericPageLeave() {
		return new Promise((resolve) => {
			this.pageTransition.leave(resolve)
		})
	}

	genericPageAfter() {
		this.pageTransition.after()
	}

	resize() {
		if (this.scriptRefs) {
			this.scriptRefs.forEach((component) => {
				if (component.resize) {
					component.resize()
				}
			})
		}
	}

	update() {
		if (this.scriptRefs) {
			this.scriptRefs.forEach((component) => {
				if (component.update) {
					component.update()
				}
			})
		}
	}
}
