import Base from '../../../src/js/classes/Base'

export default class ExampleSection extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {
				accordions: '.c-Accordion',
				images: '.c-Image',
				accordionContainer: '.s-Pullouts__accordions',
			},
			options: {},
			classes: {
				active: 'active',
			},
			state: {},
		})
	}

	init() {
		this.elements.accordions = [...this.elements.accordions]
		this.elements.images = [...this.elements.images]
		this.addEvents()
	}

	addEvents() {
		this.elements.accordions.forEach((accordion) => {
			accordion.addEventListener('accordion-open', this.onAccordionOpen.bind(this))
		})
		this.elements.accordions.forEach((accordion) => {
			accordion.addEventListener('accordion-close', this.onAccordionClose.bind(this))
		})
	}

	onAccordionOpen(e) {
		const childrenArray = Array.from(this.elements.accordionContainer.children)
		const index = childrenArray.indexOf(e.target)
		this.elements.images[index].classList.add(this.classes.active)
	}

	onAccordionClose(e) {
		const childrenArray = Array.from(this.elements.accordionContainer.children)
		const index = childrenArray.indexOf(e.target)
		this.elements.images[index].classList.remove(this.classes.active)
	}
}
