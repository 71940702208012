export default class BarbaBodyHelper {
	constructor() {}

	getClassesAndDataAttributes(data) {
		if (data.current.container) {
			let nextHtml = data.next.html
			let parser = new DOMParser()

			let htmlDoc = parser.parseFromString(
				nextHtml.replace(
					/(<\/?)body( .+?)?>/gi,
					'$1notbody$2>',
					nextHtml
				),
				'text/html'
			)

			let bodyClasses = htmlDoc.body.getAttribute('class')

			document.body.className = '' // Clear existing classes
			document.body.classList.add(...bodyClasses.split(' '))

			// Remove all existing data attributes
			for (const attr of [...document.body.attributes]) {
				if (attr.name.startsWith('data-s')) {
					document.body.removeAttribute(attr.name)
				}
			}

			// Add new data attributes
			let bodyDataAttrs = htmlDoc.body.attributes

			for (const attr of bodyDataAttrs) {
				if (attr.name.startsWith('data-s')) {
					document.body.setAttribute(attr.name, attr.value)
				}
			}
		}
	}
}
