import 'lazysizes'
import Barba from '@barba/core'
import mitt from 'mitt'

import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Utils } from './utils/utils'

import SmoothScroll from './classes/SmoothScroll'
import Page from './classes/Page'

GSAP.registerPlugin(ScrollTrigger)

let instance = null

export default class Bonobo {
	constructor() {
		if (instance) {
			return instance
		}

		instance = this

		window.emitter = mitt()
		this.siteAttributes = Utils.getSiteSettingsAsAttributes()
		this.viewportHeight = Utils.setElementViewportHeight()

		this.initSettings()

		this.page = new Page({
			hasTransitions: this.globals.pageTransitionEnabled === '1' ? true : false,
		})

		this.init()

		GSAP.ticker.add(() => {
			this.update()
		})

		ScrollTrigger.addEventListener('refresh', this.resize.bind(this))
	}

	init() {
		this.page.init()
	}

	initSettings() {
		this.globals = {}

		this.globals = {
			isTouchDevice: Utils.isTouchDevice(),
			smoothScroll: this.siteAttributes['data-s-has-smooth-scroll'],
			pageTransitionEnabled: this.siteAttributes['data-s-has-page-transition'],
		}

		if (this.globals.smoothScroll === '1') {
			this.smoothScroll = new SmoothScroll({
				speed: this.globals.scrollSpeed,
			})
		}

		if (this.globals.pageTransitionEnabled === '1') {
			this.createBarba()
		}
	}

	createBarba() {
		this.barba = Barba

		this.barba.init({
			schema: {
				prefix: 'data-transition',
			},
			// debug: true,
			transitions: [
				{
					name: 'default-transition',
					once: (data) => {
						this.page.genericPageOnce()
					},
					leave: async (data) => {
						await this.page.genericPageLeave()
					},
					beforeEnter: async (data) => {
						await this.page.unmountScripts(data)
					},
					after: async (data) => {
						await this.page.mountScripts(data)

						this.page.genericPageAfter()
					},
				},
			],
		})
	}

	resize() {
		this.viewportHeight = Utils.setElementViewportHeight()

		if (this.page) {
			this.page.resize()
		}
	}

	update() {
		if (this.page) {
			this.page.update()
		}
	}
}

document.addEventListener('DOMContentLoaded', () => {
	new Bonobo()
})
