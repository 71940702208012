import GSAP from 'gsap'
import Base from '../../../src/js/classes/Base'

export default class Menu extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {},
			settings: {},
			options: {},
			state: {
				menuOpen: false,
			},
		})

		this.mm = GSAP.matchMedia()
	}

	init() {
		this.addEvents()

		this.mm.add(
			{
				isDesktop: `(min-width: 1024px)`,
				isMobile: `(max-width: 1023px)`,
			},
			(context) => {
				let { isDesktop, isMobile } = context.conditions

				return () => {
					// clean up stuff that is run when breakpoint no longer matches
					if (isMobile && this.state.menuOpen && !this.globals.isTouchDevice) {
						this.setState({ menuOpen: false })
						window.emitter.emit('menuClosed')
					}
				}
			}
		)
	}

	onStateChange(newState) {
		//
		if ('menuOpen' in newState) {
			if (newState.menuOpen) {
				this.openMenu()
			} else {
				this.closeMenu()
			}
		}
	}

	stateTriggerMenu() {
		this.setState({
			menuOpen: !this.state.menuOpen,
		})
	}

	openMenu() {
		this.element.classList.add('open')
	}

	closeMenu() {
		this.element.classList.remove('open')
	}

	addEvents() {
		window.emitter.on('menuToggled', () => this.stateTriggerMenu())
	}

	unmount() {}
}
