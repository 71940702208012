import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Base from '../../../src/js/classes/Base'

export default class Header extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {
				serviceLinks: '.s-Header__services__link',
				serviceItems: '.s-Header__services__subnav__item',
				subNav: '.s-Header__services__subnav',
				navBackground: '.s-Header__bg',
			},
			state: {
				isHidden: false,
				menuToggled: false,
			},
			classes: {
				isHidden: 'is-hidden',
				isActive: 'is-active',
			},
			settings: {},
			options: {},
		})

		this.scrollOffset = 200

		this.handleMouseEnter = this.createMouseEnterHandler()
		this.handleMenuToggleEvent = this.handleMenuToggleEvent.bind(this)
	}

	init() {
		this.addEvents()
		this.setupScrollTrigger()
	}

	setupScrollTrigger() {
		//
		ScrollTrigger.create({
			trigger: document.body,
			start: `top top-=${this.scrollOffset}`,
			onUpdate: (self) => {
				if (self.direction === -1) {
					this.setState({ isHidden: false })
				} else {
					this.setState({ isHidden: true })
				}
			},
		})
	}

	showHeader() {
		this.element.classList.add(this.classes.isHidden)
	}

	hideHeader() {
		this.element.classList.remove(this.classes.isHidden)
	}

	onStateChange(newState) {
		//
		if ('isHidden' in newState) {
			if (newState.isHidden) {
				this.showHeader()
			} else {
				this.hideHeader()
			}
		}
	}

	handleMenuToggleEvent() {
		window.emitter.emit('menuToggled')
	}

	updateItemDisplay(idx) {
		this.resetItemDisplay()
		this.displayItem(idx)
	}

	resetItemDisplay() {
		this.elements.serviceItems.forEach((itm) => {
			itm.style.display = 'none'
		})
	}

	displayItem(idx) {
		this.elements.serviceItems[idx].style.display = 'flex'
	}

	createMouseEnterHandler() {
		return (event) => {
			const idx = this.elements.serviceLinks.indexOf(event.currentTarget)

			event.currentTarget.classList.add('active')

			this.updateItemDisplay(idx)

			this.elements.serviceLinks.forEach((link, index) => {
				if (index !== idx) {
					link.classList.remove('active')
				}
			})
		}
	}

	addEvents() {
		this.elements.serviceLinks.forEach((el) => {
			el.addEventListener('mouseenter', this.handleMouseEnter)
		})
	}

	removeEvents() {
		this.elements.serviceLinks.forEach((el) => {
			el.removeEventListener('mouseenter', this.handleMouseEnter)
		})
	}

	// DON'T NEED TO REMOVE AS WE'RE NOT USING PAGE TRANSITIONS
	// THE PAGE REFRESH WILL REMOVE THE EVENTS BY DEFAULT
	// BUT THESE ARE HERE INCASE WE SWITCH TO BARBA.js
	unmount() {
		//this.removeEvents()
	}
}
