import HLS from 'hls.js'
import GSAP from 'gsap'

export default class VideoHelper {
	constructor(videoElement, videoSource, platform, mediaQuery, hasMobile, hasVideoControls, videoWrapper, btn) {
		this.videoElement = videoElement
		this.videoSource = videoSource
		this.platform = platform
		this.mediaQuery = mediaQuery
		this.hasMobile = hasMobile
		this.hasVideoControls = hasVideoControls
		this.videoWrapper = videoWrapper
		this.playBtn = btn || null

		this.canObserve = false
		this.hasEnteredViewport = false
		this.isPlaying = false

		this.init()
	}

	init() {
		if (this.platform === 'vimeo') {
			// settings
			this.hls = new HLS({
				lowLatencyMode: true,
				capLevelToPlayerSize: true,
				autoStartLoad: true,
				startLevel: 10,
				startFragPrefetch: true,
			})

			this.HLSOnLoad = this.HLSOnLoad.bind(this)
		}

		if (this.hasMobile) {
			this.initMediaQuery()
		} else {
			this.canObserve = true

			GSAP.set(this.videoWrapper, {
				display: 'block',
			})
		}

		if (this.hasVideoControls) {
			this.addPlayEvent(this.playBtn)
		}

		this.initIntersectionObserver()
	}

	initMediaQuery() {
		// only used when 2 videos are used

		this.mm = GSAP.matchMedia()
		this.mm.add(this.mediaQuery, () => {
			this.canObserve = true

			if (this.hasEnteredViewport && !this.hasLoaded) {
				this.loadVideo()
			}

			if (this.hasLoaded && this.canObserve && !this.hasVideoControls) {
				if (!this.isPlaying) {
					this.playVideo()
				}
			}

			GSAP.set(this.videoWrapper, {
				display: 'block',
			})

			return () => {
				this.canObserve = false
				this.videoElement.pause()
				this.isPlaying = false // Reset playing flag
				GSAP.set(this.videoWrapper, {
					display: 'none',
				})
			}
		})
	}

	HLSOnLoad() {
		// play hls
		//console.log('🎥 Using HLS.js')

		if (!this.hasVideoControls) {
			this.playVideo()
		}
	}

	initIntersectionObserver() {
		// intersection config, and video states

		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0,
		}

		this.intersectionObserver = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					if (!this.hasEnteredViewport) {
						this.hasEnteredViewport = true // Set flag when video enters viewport
						if (this.canObserve && !this.hasLoaded) {
							this.loadVideo()
						} else if (this.canObserve && this.hasLoaded && !this.isPlaying && !this.hasVideoControls) {
							this.playVideo()
						}
					} else if (this.hasEnteredViewport && this.canObserve && this.hasLoaded && !this.isPlaying && !this.hasVideoControls) {
						this.playVideo()
					}
				} else {
					if (this.hasEnteredViewport) {
						this.pauseVideo()
					}
				}
			})
		}, options)

		this.intersectionObserver.observe(this.videoElement)
	}

	loadVideo() {
		// Load video depending on platform type

		if (this.platform === 'media-library') {
			if (!this.videoElement.src) {
				this.videoElement.src = this.videoSource.getAttribute('data-src')
				this.videoElement.load()
				this.hasLoaded = true
				//console.log('loaded')
			}
		} else if (this.platform === 'vimeo') {
			if (HLS.isSupported()) {
				this.hls.loadSource(this.videoSource.getAttribute('data-src'))
				this.hls.attachMedia(this.videoElement)

				this.hasLoaded = true

				this.hls.on(HLS.Events.MEDIA_ATTACHED, this.HLSOnLoad)
			} else {
				/**
				 * THIS IS WHERE WE'LL ADD A BACKUP METHOD
				 */
				alert('hls is not supported')
				this.loadBackupVideo()
			}
		}
	}

	playVideo() {
		// play video

		if (this.videoElement.paused) {
			this.videoElement.play()
			this.isPlaying = true // Set playing flag
			//console.log('playing')
		}
	}

	pauseVideo() {
		// pause video

		if (!this.videoElement.paused) {
			this.videoElement.pause()
			this.isPlaying = false // Reset playing flag
			//console.log('paused')
		}
	}

	loadBackupVideo() {
		// handle backup for when HLS is not supported

		if (!this.videoElement.src) {
			this.videoSource.type = 'video/mp4'
			this.videoElement.src = this.videoSource.getAttribute('data-src')
			this.videoElement.load()
			this.hasLoaded = true
			//console.log('loaded Backup')
		}

		//console.log('🎥 Using Backup')
	}

	addControls() {
		this.videoElement.controls = true
		this.videoElement.currentTime = 0
		this.videoElement.muted = false

		this.playVideo()
	}

	addPlayEvent(btn) {
		if (btn == null) return

		btn.addEventListener('click', () => {
			btn.style.display = 'none'
			this.addControls()
		})
	}

	disconnect() {
		// unmount stuff
		if (this.hls && HLS.isSupported()) {
			this.hls.off(HLS.Events.MEDIA_ATTACHED, this.HLSOnLoad)
			this.hls.destroy()
		}

		if (this.intersectionObserver) {
			this.intersectionObserver.disconnect()
		}

		if (this.mm) {
			this.mm.kill()
		}
	}
}
