import GSAP from 'gsap'

export default class PageTransition {
	constructor(config) {
		this.element = config.element
	}

	once() {
		GSAP.set(this.element, {
			y: 0,
		})

		return GSAP.to(this.element, {
			delay: 0.5,
			y: '-100%',
			ease: 'circ.inOut',
			duration: 1,
			onComplete: () => {
				window.emitter.emit('pageReady')

				GSAP.set(this.element, {
					y: '100%',
				})
			},
		})
	}

	leave(resolve) {
		const TL = GSAP.timeline()

		TL.to(
			this.element,
			{
				y: 0,
				duration: 1,
				ease: 'circ.inOut',
				onComplete: () => resolve(),
			},
			'<'
		)
	}

	after() {
		const TL = GSAP.timeline()

		TL.to(this.element, {
			delay: 0.5,
			y: '-100%',
			ease: 'circ.inOut',
			duration: 1,
			onComplete: () => {
				window.emitter.emit('pageReady')

				GSAP.set(this.element, {
					y: '100%',
				})
			},
		})
	}
}
