import Base from '../../../src/js/classes/Base'

export default class Process extends Base {
	constructor(el) {
		super({
			element: el,
			elements: {
				items: '.s-Process__item',
				details: '.s-Process__pd',
			},
			state: {
				index: 0,
			},
			classes: {
				active: 'active',
			},
		})
		this.elements.items = [...this.elements.items]
		this.elements.details = [...this.elements.details]
	}

	init() {
		this.addEvents()
	}

	onStateChange(newState) {
		if ('index' in newState) {
			this.setActive(newState.index)
		}
	}

	onClickItem(e) {
		const target = e.target
		const index = parseInt(target.dataset.index)
		this.setState({
			index: index,
		})
	}

	setActive(newIndex) {
		this.elements.items.forEach((item) => {
			item.classList.remove(this.classes.active)
		})
		this.elements.details.forEach((item) => {
			item.classList.remove(this.classes.active)
		})
		this.elements.items[newIndex].classList.add(this.classes.active)
		this.elements.details[newIndex].classList.add(this.classes.active)
	}

	addEvents() {
		this.elements.items.forEach((item) => {
			item.addEventListener('click', this.onClickItem.bind(this))
		})
	}
}
